import { useState } from 'react'
import { Code, SegmentedControl } from '@blueprintjs/core'

import { useApiData } from 'hooks'

import StatsChart from './StatsChart'

interface Props {
  envId: EnvId
}
const Stats: React.FC<Props> = ({ envId }) => {
  const apiKeys = useApiData('/app/getApiKeys', { envId })
  const [graphState, setGraphState] = useState('local')
  return (
    <>
      <p>To get free passkey support stats from your site, add the following code:</p>

      <p><Code>&lt;script src="https://api.snapauth.app/stats.js" data-key="{apiKeys?.publishable[0].value}" data-dedupe defer&gt;&lt;/script&gt;</Code></p>

      <p>
        This is <em>entirely opt-in</em> and will <strong>not</strong> be done automatically by our Client SDKs.
        Read the <a href="https://docs.snapauth.app/#browser-stats" target="_blank">full docs</a> for config info.
      </p>

      <SegmentedControl
        options={[{label: 'This environment', value: 'local'}, {label: 'Global', value: 'global'}]}
        value={graphState}
        onValueChange={(newValue) => setGraphState(newValue)}
        inline
      />
      {/* This is a dumb hack because the data isn't reloading properly when env changes. Seems to be a bug in the hook. */}
      {graphState === 'local' &&  <StatsChart envId={envId} />}
      {graphState === 'global' &&  <StatsChart envId={null} />}
    </>
  )
}

export default Stats
