import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  Colors,
} from 'chart.js'

import { useApiData } from 'hooks'

// Register the required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Colors,
  Title,
  Tooltip,
  Legend
)


const StatsChart: React.FC<{envId: EnvId|null}> = ({envId}) => {
  const stats = useApiData('/app/stats', { envId })
  if (!stats) return null
  const data = stats.data
    .map(d => ({...d, date: new Date(d.date)}))
    .sort((a, b) => a.date.getTime() - b.date.getTime())

  if (data.length === 0) {
    return <p>No stats available yet. Stats are calculated about once a day.</p>
  }
  // console.debug(data)

  // Prepare data for Chart.js
  const chartData: ChartData<'line'> = {
    labels: data.map(d => d.date.toLocaleDateString()),
    datasets: [
      {
        label: 'WebAuthn',
        data: data.map(d => d.webauthn * 100),
      },
      {
        label: 'Autofill',
        data: data.map(d => d.conditionalGet * 100),
      },
      {
        label: 'Automatic Create',
        data: data.map(d => d.conditionalCreate * 100),
      },
    ],
  }

  // Chart.js options
  const options: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Support %',
        },
      },
    },
  }

  return <Line data={chartData} options={options} />
}

export default StatsChart
